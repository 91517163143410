import { PoolConfig, QuoteToken, PoolCategory } from '../types'

const pools: PoolConfig[] = [
  {
    sousId: 98, // V1
    tokenName: 'BUSD',
    tokenLabel: 'BUSD',
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    stakingTokenName: QuoteToken.PHCF,
    stakingTokenAddress: '0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
    stakingTokenDecimals: 18,
    contractAddress: {
      97: '',
      56: '0x0F654d92c69E4708bE5e221C7d5fecCcBcC648EE',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://phcfinance.me',
    harvest: true,
    tokenPerBlock: '0.005',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    isBush: true,
    isLp: false,
    bushVersion: 1,
    getUrl: '/#/swap?outputCurrency=0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
  },
  {
    sousId: 99, // V1
    tokenName: 'WBNB',
    tokenLabel: 'WBNB',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    stakingTokenName: QuoteToken.PHCF,
    stakingTokenAddress: '0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
    stakingTokenDecimals: 18,
    contractAddress: {
      97: '',
      56: '0x39FBfeF9111271C8dCEe1909cBFaCEaa1B3F972d',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.binance.com',
    harvest: true,
    tokenPerBlock: '0.000015',
    sortOrder: 999,
    isFinished: false,
    tokenDecimals: 18,
    isBush: true,
    isLp: false,
    bushVersion: 1,
    getUrl: '/#/swap?outputCurrency=0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
  },
]

export default pools
