import React, { useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS, Text, Flex, Link, TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon } from '@phcfinance/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPriceList, useFetchProfile, useFetchPublicData } from 'state/hooks'
import useGetDocumentTitlePrice from './hooks/useGetDocumentTitlePrice'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
import Page from './components/layout/Page'
import Pools from './views/Pools'
// import GlobalCheckBullHiccupClaimStatus from './views/Collectibles/components/GlobalCheckBullHiccupClaimStatus'
import history from './routerHistory'

const Footer = styled.div`
  background-color: #161616;
`

const StyledPage = styled(Page)`
  min-height: unset;
  text-align: center;
`

const StyledFooterInfo = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`

const StyledInnerPage = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

const StyledFooterNavigate = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`

const StyledImg = styled.img`
  
`

const FooterNavItem = styled(Link)`
  color: #fff;
  font-size: 14px;
  margin-top: 15px;
`

const IconLink = styled(Link)`
  text-decoration: none;
  margin-top: 20px;
  margin-right: 10px;
  color: #fff;

  &: hover {
    color: #FFBF00;
  }
`

const FooterNavTitle = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 50px;
`

const FooterNavTitleBottom = styled.div`
  width: 25px;
  height: 4px;
  background-color: #EFBD3C;
`

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Lottery = lazy(() => import('./views/Lottery'))
const Referrals = lazy(() => import('./views/Referrals'))
const Ifos = lazy(() => import('./views/Ifos'))
const Vault = lazy(() => import('./views/Vault'))
const NotFound = lazy(() => import('./views/NotFound'))
// const Collectibles = lazy(() => import('./views/Collectibles'))
// const Teams = lazy(() => import('./views/Teams'))
// const Team = lazy(() => import('./views/Teams/Team'))
// const Profile = lazy(() => import('./views/Profile'))
const Bush = lazy(() => import('./views/Bush'))
const Audit = lazy(() => import('./views/Audit'))
const NftMarketplace = lazy(() => import('./views/NftMarketplace'))
const NftStaking = lazy(() => import('./views/NftStaking'))
const Roadmap = lazy(() => import('./views/Roadmap'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useEagerConnect()
  useFetchPublicData()
  useFetchProfile()
  // useFetchPriceList()
  useGetDocumentTitlePrice()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Farms tokenMode />
            </Route>
            <Route path="/bush">
              <Bush />
            </Route>
            <Route path="/lottery">
              <Lottery />
            </Route>
            <Route path="/ifo">
              <Ifos />
            </Route>
            <Route path="/referrals">
              <Referrals />
            </Route>
            <Route path="/vault">
              <Vault />
            </Route>
            <Route path="/nftmarket">
              <NftMarketplace />
            </Route>
            <Route path="/nftstaking">
              <NftStaking />
            </Route>
            <Route path="/audit">
              <Audit />
            </Route>
            <Route path="/roadmap">
              <Roadmap />
            </Route>            
          </Switch>
        </Suspense>
      </Menu>
      <Footer>
        <StyledPage>
          <StyledInnerPage>
            <StyledFooterInfo>
              <Flex>
                <StyledImg src="/images/logo.png"/>
              </Flex>
              <Flex style={{marginTop: 10, marginBottom: 10}}>
                <Text style={{color: '#EFBD3C', fontSize: '16px'}}>
                  Phuket Holiday Coin Limited
                </Text>
              </Flex>
              <Flex style={{marginTop: 10}}>
                <StyledImg src="/images/footer-address-icon.png" style={{height: 'max-content'}}/>
                <Text style={{color: '#fff', fontSize: '14px', textAlign: 'left', paddingLeft: 15}}>
                  House of Francis, Room 303, Ile Du Port, Mahe, <br/>
                  Glacis, SEYCHELLES, SC, 188976
                </Text>
              </Flex>
              <Flex style={{marginTop: 10}}>
                <StyledImg src="/images/footer-address-icon.png" style={{height: 'max-content'}}/>
                <Text style={{color: '#fff', fontSize: '14px', textAlign: 'left', paddingLeft: 15}}>
                  GSM Building: 141 Middle Road #05-06 GSM <br/>
                  Building Singapore 188976
                </Text>
              </Flex>
            </StyledFooterInfo>
            <StyledFooterNavigate>
              <FooterNavTitle>
                About
              </FooterNavTitle>
              <FooterNavTitleBottom> </FooterNavTitleBottom>
              <FooterNavItem href="https://phuketholidaycoin.me/" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                <StyledImg src="/images/internet-icon.png" style={{paddingRight: 10}}/>
                Phuketholidaycoin.Me
              </FooterNavItem>
              <FooterNavItem href="https://phcpay.me" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                <StyledImg src="/images/internet-icon.png" style={{paddingRight: 10}}/>
                Phcpay.Me
              </FooterNavItem>
              <FooterNavItem href="https://plaloma.me" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                <StyledImg src="/images/internet-icon.png" style={{paddingRight: 10}}/>
                Plaoma.Me
              </FooterNavItem>
              <FooterNavItem href="https://www.youtube.com/channel/UCDVBa3DFuWwix2KhpY5R2uA" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                <StyledImg src="/images/youtube-icon.png" style={{paddingRight: 10}}/>
              </FooterNavItem>
            </StyledFooterNavigate>
            {/* <StyledFooterNavigate>
              <FooterNavTitle>
                Help
              </FooterNavTitle>
              <FooterNavTitleBottom> </FooterNavTitleBottom>
              <FooterNavItem href="/" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                Customer Support
              </FooterNavItem>
              <FooterNavItem href="/" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                Guides
              </FooterNavItem>             
            </StyledFooterNavigate> */}
            <StyledFooterNavigate>
              <FooterNavTitle>
                Developers
              </FooterNavTitle>
              <FooterNavTitleBottom> </FooterNavTitleBottom>
              <FooterNavItem href="https://github.com/solidproof/projects/tree/main/PHC%20Finance" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                KYC
              </FooterNavItem>
              <FooterNavItem href="https://phc.gitbook.io/phc-finance" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                Documentation
              </FooterNavItem>
              <FooterNavItem href="https://github.com/Coinsult/solidity/blob/main/PhuketHolidayCoinAudit.pdf" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                Audits
              </FooterNavItem> 
              <FooterNavItem href="https://phc.gitbook.io/phc-finance/roadmap" bold={false} style={{color: '#fff', fontSize: '14px'}}>
                Roadmap
              </FooterNavItem>                
            </StyledFooterNavigate>
            <StyledFooterNavigate>
              <FooterNavTitle>
                Follow Us
              </FooterNavTitle>
              <FooterNavTitleBottom> </FooterNavTitleBottom>
              <Flex>
                <IconLink href="https://twitter.com/CoinPhc">
                  <TwitterIcon fill="transparent"/>
                </IconLink>
                <IconLink href="https://www.reddit.com/user/PHFinance">
                  <RedditIcon fill="transparent"/>
                </IconLink>
                <IconLink href="https://t.me/PHC_coin">
                  <TelegramIcon fill="transparent"/>
                </IconLink>
                <IconLink href="#">
                  <InstagramIcon fill="transparent"/>
                </IconLink>
              </Flex>
              
            </StyledFooterNavigate>
          </StyledInnerPage>
          
        </StyledPage>
        <StyledPage style={{paddingTop: 15, paddingBottom: 15, borderTop: '1px solid rgba(255,255,255,0.1)'}}>
          <Text style={{color: '#aaa', fontSize: '14px'}}>
            2022 © Copyright. All Rights Reserved  - Phuket Holiday Coin Limited
          </Text>
        </StyledPage>
      </Footer>
      
      
      <EasterEgg iterations={2} />
      <ToastListener />
      {/* <GlobalCheckBullHiccupClaimStatus /> */}
    </Router>
  )
}

export default React.memo(App)
