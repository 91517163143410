import contracts from './contracts'
import { FarmConfig, QuoteToken } from '../types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    isTokenOnly: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xe0c74f3a50cb444535b701b374cd094e585dd196', // COINK-bscBUSD LP
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.bscBUSD,
    quoteTokenAdresses: contracts.bscbusd,
  },
  {
    pid: 1,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-bscBUSD LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xe0c74f3a50cb444535b701b374cd094e585dd196',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.bscBUSD,
    quoteTokenAdresses: contracts.bscbusd,
  },
  {
    pid: 2,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x082e3bc57ae5a401a4f16ce85cabb5c6efc57cf2',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },



  {
    pid: 3,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-VIPER LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x60452c298e60109844e91555035d313fdfd36ac2',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.VIPER,
    quoteTokenAdresses: contracts.viper,
  },
  {
    pid: 4,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 9,
    lpSymbol: 'COINK-MOONI LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xf6e8570c6216fed6202dbdd088799b757c1c0c0a',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.MOONI,
    quoteTokenAdresses: contracts.mooni,
  },
  {
    pid: 5,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 9,
    lpSymbol: 'COINK-LONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x8bd80194c9412e4206085bed3be7add2f89d6f81',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.LONE,
    quoteTokenAdresses: contracts.lone,
  },
  {
    pid: 6,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 9,
    lpSymbol: 'COINK-KURO LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x6bb8c0d19b4e3ca535fe7d7a3f8df4dc41cda821',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.KURO,
    quoteTokenAdresses: contracts.kuro,
  },
  {
    pid: 7,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-XYA LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x92711504b415ac0c4d53b1c38b6314e118a9e3ba',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.XYA,
    quoteTokenAdresses: contracts.xya,
  },
  {
    pid: 24,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-JENN LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x80178d90ec24a3c636b7ea99b7733286846a65a9',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.JENN,
    quoteTokenAdresses: contracts.jenn,
  },
  {
    pid: 25,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-hMOCHI LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x44beec5d826de12ac091d49853c85429f59cad3d',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.hMOCHI,
    quoteTokenAdresses: contracts.hmochi,
  },
  {
    pid: 29,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-YIN LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x4ac0b1f2ed089b455c421d2fa7ab28bdf32e5f8a',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.YIN,
    quoteTokenAdresses: contracts.yin,
  },
  {
    pid: 30,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'COINK-YANG LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x2dc171b7dc98d3f5f14a2d84caabf67affdadf8e',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.YANG,
    quoteTokenAdresses: contracts.yang,
  },
  {
    pid: 31,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 9,
    lpSymbol: 'COINK-ONEMOON LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x054e6fee23bb791c126fde3e9f839d3cdc7e6794',
    },
    tokenSymbol: 'COINK',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
    },
    quoteTokenSymbol: QuoteToken.ONEMOON,
    quoteTokenAdresses: contracts.onemoon,
  },



  {
    pid: 8,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'MOONI-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x8fafb20266e8579d4e8952978a9cd0e4c65bd198',
    },
    tokenSymbol: 'MOONI',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x8d4f19bec883ba20f4f295706c53f760cd0bc2b0',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 9,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'ONE-bscINJ LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x2e16fd7263316f74be8cc9a9cdfc536d2f0440b5',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken.bscINJ,
    quoteTokenAdresses: contracts.bscinj,
  },
  {
    pid: 10,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'UST-bscBUSD LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x28718c58a13825d6f605fc7994a1b3ec5ec3ca38',
    },
    tokenSymbol: 'UST',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x224e64ec1bdce3870a6a6c777edd450454068fec',
    },
    quoteTokenSymbol: QuoteToken.bscBUSD,
    quoteTokenAdresses: contracts.bscbusd,
  },
  {
    pid: 11,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'ONE-bscBUSD LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x5f2464c92f3d53ede97d7ac06036b6e831a22249',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken.bscBUSD,
    quoteTokenAdresses: contracts.bscbusd,
  },
  {
    pid: 12,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'ONE-UST LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x6574026db45ba8d49529145080489c3da71a82df',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken.UST,
    quoteTokenAdresses: contracts.ust,
  },
  {
    pid: 13,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'ONE-1ETH LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x1e77990977bd723f4bfaefea68bf372462683fa2',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken['1ETH'],
    quoteTokenAdresses: contracts['1eth'],
  },
  {
    pid: 14,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 8,
    lpSymbol: 'ONE-1WBTC LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x243130d5a9bf54fe661adca850c481682dd502e6',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken['1WBTC'],
    quoteTokenAdresses: contracts['1wbtc'],
  },
  {
    pid: 15,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'ONE-bscADA LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xcddb7ce8b8ab029faf9f40ffc5ed7d2029283d74',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken.bscADA,
    quoteTokenAdresses: contracts.bscada,
  },
  {
    pid: 16,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'VIPER-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x96025483bd32c645b822a5a08004b84d674537cb',
    },
    tokenSymbol: 'VIPER',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xea589e93ff18b1a1f1e9bac7ef3e86ab62addc79',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 17,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'LONE-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x5985b0e3302ae7adac2e4c793b5c3e47e1287065',
    },
    tokenSymbol: 'LONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x91acbfc784c73777d8013db48afc2d698a4ae751',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 18,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'KURO-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xe7da7cdbf55fe51ade5b5d44bccd1958dd4e88fe',
    },
    tokenSymbol: 'KURO',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x3e018675c0ef63eb361b9ef4bfea3a3294c74c7b',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 19,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'XYA-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x1485a496f816f940c510d634e48f8c66b78dc99e',
    },
    tokenSymbol: 'XYA',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x9b68bf4bf89c115c721105eaf6bd5164afcc51e4',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 20,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'bscINJ-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x2e16fd7263316f74be8cc9a9cdfc536d2f0440b5',
    },
    tokenSymbol: 'bscINJ',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xd34e675703ccf11ac1036b75782b99fcbacfc526',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 21,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: '1ETH-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x1e77990977bd723f4bfaefea68bf372462683fa2',
    },
    tokenSymbol: '1ETH',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x6983d1e6def3690c4d616b13597a09e6193ea013',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 22,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: '1WBTC-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x243130d5a9bf54fe661adca850c481682dd502e6',
    },
    tokenSymbol: '1WBTC',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x3095c7557bcb296ccc6e363de01b760ba031f2d9',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 23,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'bscADA-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xcddb7ce8b8ab029faf9f40ffc5ed7d2029283d74',
    },
    tokenSymbol: 'bscADA',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x582617bd8ca80d22d4432e63fda52d74dcdcee4c',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 26,
    isTokenOnly: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'WONE',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x5f2464c92f3d53ede97d7ac06036b6e831a22249',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken.bscBUSD,
    quoteTokenAdresses: contracts.bscbusd,
  },
  {
    pid: 27,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'JENN-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x0ea67cfe61d2847e1b14a374a884a35529267818',
    },
    tokenSymbol: 'JENN',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x2f459dd7cbcc9d8323621f6fb430cd0555411e7b',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 28,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'hMOCHI-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x7cb796ab46f5a10f181f9ba7dc5215e989ef6f49',
    },
    tokenSymbol: 'hMOCHI',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x0dd740db89b9fda3baadf7396ddad702b6e8d6f5',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },




  
  {
    pid: 32,
    isVisible: true,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'ONE-1SUSHI LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x3d42ae00c95bf85ee3c9106a7843cb592c045119',
    },
    tokenSymbol: 'ONE',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    },
    quoteTokenSymbol: QuoteToken['1SUSHI'],
    quoteTokenAdresses: contracts['1sushi'],
  },



  
  {
    pid: 33,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'YIN-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x3bf318bee22de37dfc77e208ef70c36251502b2d',
    },
    tokenSymbol: 'YIN',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xe59aa7f9e91b4cc6c25d3542cecb851e0316138c',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 34,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'YANG-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0xef98f91df2981c8a046f0ec704dbe3cc81efa2ed',
    },
    tokenSymbol: 'YANG',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0x340042552d19211795dbe55d84fa2e63bc49b890',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 35,
    isVisible: false,
    isV2: true,
    decimals: 9,
    quoteDecimals: 18,
    lpSymbol: 'ONEMOON-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x549f26c652c2ebf4b322936a2949b4d05a67ff82',
    },
    tokenSymbol: 'ONEMOON',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xcb35e4945c7f463c5ccbe3bf9f0389ab9321248f',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 36,
    isVisible: false,
    isV2: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: '1SUSHI-ONE LP',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x3d42ae00c95bf85ee3c9106a7843cb592c045119',
    },
    tokenSymbol: '1SUSHI',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xbec775cb42abfa4288de81f387a9b1a3c4bc552a',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
  {
    pid: 37,
    isTokenOnly: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'VIPER',
    lpAddresses: {
      1666700000: '',
      1666600000: '0x96025483bd32c645b822a5a08004b84d674537cb',
    },
    tokenSymbol: 'VIPER',
    tokenAddresses: {
      1666700000: '',
      1666600000: '0xea589e93ff18b1a1f1e9bac7ef3e86ab62addc79',
    },
    quoteTokenSymbol: QuoteToken.ONE,
    quoteTokenAdresses: contracts.wone,
  },
]

export default farms
