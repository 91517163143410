import contracts from './contracts'
import { FarmConfig, QuoteToken } from '../types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    isPsc: true,
    isTokenOnly: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'PHCF',
    lpAddresses: {
      97: '',
      56: '0x57746D75428de9Ff70210987D80E46c4530e46f9', // PHCF-BUSD LP
    },
    tokenSymbol: 'PHCF',
    tokenAddresses: {
      97: '',
      56: '0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    isPsc: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'PHCF-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x57746D75428de9Ff70210987D80E46c4530e46f9',
    },
    tokenSymbol: 'PHCF',
    tokenAddresses: {
      97: '',
      56: '0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    isPsc: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'PHCF-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xB64A9D026BB85b7aAD106f79C6aF2EC70C91FBe6',
    },
    tokenSymbol: 'PHCF',
    tokenAddresses: {
      97: '',
      56: '0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    isPsc: true,
    isTokenOnly: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // BNB-BUSD
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 5,
    isPsc: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'PHC-BNB',
    lpAddresses: {
      97: '',
      56: '0xA1e2a75FF537d9d715065D6605ca793f9c160260', // PHC-BNB LP
    },
    tokenSymbol: 'PHC',
    tokenAddresses: {
      97: '',
      56: '0x2EB3f218a9Ab652B349278F4EAcf1a2488C1e704',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    isPsc: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'PHCP-BNB',
    lpAddresses: {
      97: '',
      56: '0xafEE2Eb692d7Bc77B9406F481cBb548d8516038E', // PHCP-BNB LP
    },
    tokenSymbol: 'PHCP',
    tokenAddresses: {
      97: '',
      56: '0x9E824e2c7966E4b850aB7E06125de32b6D4739E0',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 7,
    isPsc: true,
    isVisible: true,
    decimals: 18,
    quoteDecimals: 18,
    lpSymbol: 'PHCP-BUSD',
    lpAddresses: {
      97: '',
      56: '0x4abA10e6CE67843b8e4b60Db2366c2a140705196', // PHCP-BUSD LP
    },
    tokenSymbol: 'PHCP',
    tokenAddresses: {
      97: '',
      56: '0x9E824e2c7966E4b850aB7E06125de32b6D4739E0',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
