export default {
  cake: {
    97: '',
    56: '0xfe283D9c54fEE4D239FB72323106b58f086D2E78',
  },
  syrup: {
    97: '',
    56: '',
  },
  masterChef: {
    97: '',
    56: '0x686559FcC1115C3B40CDD7e1C6271706CfA4feEb',
  },
  sousChef: {
    97: '',
    56: '',
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0xBB25B82b5Ff4E50793F7aAB7EB39F8214b7f2F7A',
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x2A08Ca246AFe5aCFF247f57718A6fdEE7E318d67',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  ust: {
    56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '',
  },
  pancakeProfile: {
    56: '0x59ACa060FEB92b09B76A58036231637cCD27af91',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  referral: {
    56: '0xA11500c48E7f5Be1CeBB3De50C7cdEE0ca2e5DB4',
    97: '',
  },
}
