export default {
  cake: {
    1666700000: '',
    1666600000: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
  },
  syrup: {
    1666700000: '',
    1666600000: '',
  },
  masterChef: {
    1666700000: '',
    1666600000: '0xF2a7597b1c5759D28fa6a61ee9D6D184Dc86136d',
  },
  lottery: {
    1666700000: '',
    1666600000: '0xA36689f10f7C5504434E2E2f4B07BA512bc5254c',
  },
  lotteryNFT: {
    1666700000: '',
    1666600000: '0x61CF346F3E125A441e5bE7589D5189cD91aa1eB1',
  },
  pancakeProfile: {
    1666600000: '',
    1666700000: '',
  },
  pancakeRabbits: {
    1666600000: '',
    1666700000: '',
  },
  bunnyFactory: {
    1666600000: '',
    1666700000: '',
  },
  claimRefund: {
    1666600000: '',
    1666700000: '',
  },
  pointCenterIfo: {
    1666600000: '',
    1666700000: '',
  },
  bunnySpecial: {
    1666600000: '',
    1666700000: '',
  },
  wone: {
    1666700000: '',
    1666600000: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
  },
  mulltiCall: {
    1666600000: '0xFE4980f62D708c2A84D3929859Ea226340759320',
    1666700000: '0xbcd3451992B923531615293Cb2b2c38ba8DE9529',
  },
  bscbusd: {
    1666600000: '0x0ab43550a6915f9f67d0c454c2e90385e6497eaa',
    1666700000: '',
  },
  viper: {
    1666600000: '0xea589e93ff18b1a1f1e9bac7ef3e86ab62addc79',
    1666700000: '',
  },
  mooni: {
    1666600000: '0x8d4f19bec883ba20f4f295706c53f760cd0bc2b0',
    1666700000: '',
  },
  lone: {
    1666600000: '0x91acbfc784c73777d8013db48afc2d698a4ae751',
    1666700000: '',
  },
  kuro: {
    1666600000: '0x3e018675c0ef63eb361b9ef4bfea3a3294c74c7b',
    1666700000: '',
  },
  xya: {
    1666600000: '0x9b68bf4bf89c115c721105eaf6bd5164afcc51e4',
    1666700000: '',
  },
  bscinj: {
    1666600000: '0xd34e675703ccf11ac1036b75782b99fcbacfc526',
    1666700000: '',
  },
  ust: {
    1666600000: '0x224e64ec1bdce3870a6a6c777edd450454068fec',
    1666700000: '',
  },
  '1eth': {
    1666600000: '0x6983d1e6def3690c4d616b13597a09e6193ea013',
    1666700000: '',
  },
  '1wbtc': {
    1666600000: '0x3095c7557bcb296ccc6e363de01b760ba031f2d9',
    1666700000: '',
  },
  bscada: {
    1666600000: '0x582617bd8ca80d22d4432e63fda52d74dcdcee4c',
    1666700000: '',
  },
  jenn: {
    1666600000: '0x2f459dd7cbcc9d8323621f6fb430cd0555411e7b',
    1666700000: '',
  },
  hmochi: {
    1666600000: '0x0dd740db89b9fda3baadf7396ddad702b6e8d6f5',
    1666700000: '',
  },
  yin: {
    1666600000: '0xe59aa7f9e91b4cc6c25d3542cecb851e0316138c',
    1666700000: '',
  },
  yang: {
    1666600000: '0x340042552d19211795dbe55d84fa2e63bc49b890',
    1666700000: '',
  },
  onemoon: {
    1666600000: '0xcb35e4945c7f463c5ccbe3bf9f0389ab9321248f',
    1666700000: '',
  },
  '1sushi': {
    1666600000: '0xbec775cb42abfa4288de81f387a9b1a3c4bc552a',
    1666700000: '',
  },
  referral: {
    1666600000: '0x31bF1b99942bD90B7dB42Da858aaecDbB64ECcD9',
    1666700000: '',
  },
}
