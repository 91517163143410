import { MenuEntry } from '@phcfinance/uikit'
import { ChainId } from '@phcfinance/sdk'
import { contracts } from '../../config/constants'

const chainId = process.env.REACT_APP_CHAIN_ID
const mainToken = {
  [ChainId.MAINNET]: '0xd372380493213cEB1761673530b2b17Dfa3Eb5F0',
  [ChainId.BSCTESTNET]: '0xd372380493213cEB1761673530b2b17Dfa3Eb5F0',
  [ChainId.FANTOMNET]: '0xC490728d1cAC9F6481D8eF40F137633FA1D65956',
  [ChainId.FANTOMTESTNET]: '0xC490728d1cAC9F6481D8eF40F137633FA1D65956',
  [ChainId.HARMONYNET]: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
  [ChainId.HARMONYTESTNET]: '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
}
const usdToken = {
  [ChainId.MAINNET]: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  [ChainId.BSCTESTNET]: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  [ChainId.FANTOMNET]: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
  [ChainId.FANTOMTESTNET]: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
  [ChainId.HARMONYNET]: '0x0ab43550a6915f9f67d0c454c2e90385e6497eaa',
  [ChainId.HARMONYTESTNET]: '0x0ab43550a6915f9f67d0c454c2e90385e6497eaa',
}


const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href:
        `https://swap.phcfinance.me/#/swap?inputCurrency=BNB&outputCurrency=0xd372380493213cEB1761673530b2b17Dfa3Eb5F0`,
        target: '_blank',
      },
      {
        label: 'Liquidity',
        href:
        `https://swap.phcfinance.me/#/add/${chainId ? usdToken[chainId] : ''}/${chainId ? mainToken[chainId] : ''}`,
        target: '_blank',
      },
    ],
  },
  {
    label: 'Earn',
    icon: 'FarmIcon',
    items: [
      {
        label: 'Farms',
        href: '/farms',
      },
      {
        label: 'Pools',
        href: '/pools',
      },
    ],
  },
  {
    label: 'NFT',
    icon: 'TicketIcon',
    items: [
      {
        label: 'Plaloma',
        href: 'https://plaloma.me',
        target: '_blank',
      },
      {
        label: 'NFT Marketplace',
        href: 'https://nft.phcfinance.me',
        target: '_blank',
      },
      {
        label: 'NFT Staking',
        href: '/nftstaking',        
      },
    ],
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '/lottery',
  },  
  {
    label: 'Referrals',
    icon: 'TicketIcon',
    href: '/referrals',
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Referrals',
        href: '/referrals',
      },
      {
        label: "Github",
        href: "https://github.com/phc-finance/",
        target: '_blank',
      },
      {
        label: "Docs",
        href: "https://phc.gitbook.io/phc-finance/",
      },
      {
        label: "Pitch Deck",
        href: "https://phc.gitbook.io/phc-finance/pitch-deck/",
      },
      // {
      //   label: 'Audit',
      //   href: 'https://phcfinance.me/files/techaudit_phcfinance.pdf',
      //   target: '_blank',
      // },
    ],
  },
  
]

export default config
